import { AdvancedMarker, useAdvancedMarkerRef, InfoWindow, Pin } from "@vis.gl/react-google-maps";
import React, { useState, useCallback } from 'react';


const MarkerWithInfoWindow = () => {
    const [markerRef, marker] = useAdvancedMarkerRef();
  
    const [infoWindowShown, setInfoWindowShown] = useState(false);
  
    // clicking the marker will toggle the infowindow
    const handleMarkerClick = useCallback(
      () => setInfoWindowShown(isShown => !isShown),
      []
    );


  
    const showInMapClicked = () => {
        window.open("https://maps.google.com?q="+48.82422118190087+","+12.94080864643931 );
      };

    // if the maps api closes the infowindow, we have to synchronize our state
    const handleClose = useCallback(() => setInfoWindowShown(false), []);

    return (
      <>
        <AdvancedMarker
          ref={markerRef}
          position= {{ lat: 48.82422118190087, lng: 12.94080864643931 }}
          onClick={handleMarkerClick}
        >

        <Pin glyphColor={'#000'} borderColor={'#000'} scale={1.2}/>

        {infoWindowShown && (
          <InfoWindow 
            anchor={marker} 
            disableAutoPan={"false"}
            shouldFocus={"false"}
            onClose={handleClose}
            pixelOffset={[0,0]}
 
            style={{
              width: "250px",
              height: "110px"
            }}
            >
            <h2 className="infoWindow-heading">Autohaus Kauschinger</h2>
            <div className="infoWindow-content-line">Hauptstraße 46</div>
            <div className="infoWindow-content-line">94469 Deggendorf</div>
            <div className="infoWindow-content-line">Deutschland</div>
            <button className="maps-button" onClick={showInMapClicked}>
              Open in Maps
            </button>
          </InfoWindow>
        )}
        </AdvancedMarker>
      </>
    );
  };

export default MarkerWithInfoWindow;