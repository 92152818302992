import React, { useEffect, useState } from "react";
import {  NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import VehiclelistHeaderline from "../components/VehiclelistHeaderline.js";
import VehiclelistContentline from "../components/VehiclelistContentline.js";
import {FaAngleLeft, FaAnglesLeft, FaAngleRight, FaAnglesRight } from 'react-icons/fa6';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import ReactGA from "react-ga4";

const Vehicles = (props) => { 

  const sendGAevent = ( event ) => {
    ReactGA.event({
      category: "vehicle interactions",
      action: "vehicle_detail_clicked",
      // label: "Vehicle details have been clicked", // optional
    });
    // console.log("Google Analytics event fired: 'Vehicle details have been clicked'")
  }


  const apiBasePath= process.env.REACT_APP_API_BASE_PATH
  const b64toBlob = (myFetch) => fetch(`${myFetch}`).then(res => res.blob())

    var { pageID: currentPage }  = useParams();
    if (!currentPage){
      currentPage = 1
    }
    var myPage = parseInt(currentPage)

  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesArray, setTotalPagesArray] = useState(0);
  const [currentVehicles, setCurrentVehicles] = useState(0);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [vehiclesOverview, setVehiclesOverview] = useState([]);
  const [active, setActive] = useState()
  const [device, setDevice] = useState()
  const [pointerEvents, setPointerEvents] = useState("none")
  // const [cookies, setCookies] = useState(document.cookie)
  const navigate = useNavigate()

  useEffect(() => {
    setDevice(props.device)
    if (document.cookie !== "") {
      setPointerEvents(true)
    } else {
      setPointerEvents(false)
    }
  }, [props])

  useEffect(() => {
    window.history.replaceState({}, '')
    setScrollPosition(window.location.href + "&position" + 0);
  }, [])

  if (!currentPage){
    currentPage = 1
  }

  const location = useLocation();

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(window.location.href + "&position" + position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const calculateCurrentVehicles = async (currentPage, totalVehicles, totalPages) => {
    var endIndex = currentPage * 10
    var startIndex = 0
    if (endIndex > totalVehicles && totalVehicles !== 0) {
      endIndex = totalVehicles
      startIndex = (totalPages - 1 ) * 10
      if (startIndex % 10 === 0){
        startIndex++
      }
    } else if (totalVehicles === 0){
      endIndex = 0
    } else {
      endIndex = currentPage * 10
      // eslint-disable-next-line
      startIndex = endIndex - 9
    }
    return [startIndex, endIndex]
  }

  useEffect(() => {
    let isMounted = true; 
    const fetchVehicleOverview = async (currentPage) => {
      setLoading(true)
      // get data from specific IP
      // const res = await axios.get('http://' + window.location.hostname + `:8081/vehicleOverviewPaginated?page=${currentPage}`);
      // get data from localhost
      // eslint-disable-next-line
      const res = await axios.get(apiBasePath + `/vehicleOverviewPaginated?page=${currentPage}`);
      if (isMounted) {
        setTotalPages(res.data.totalPages);
        setLoading(false)
      }
      return res.data
    }
  
    const fetchThumpnails = async (currentPage) => {
      // get data from specific IP
      // const myThumps = await axios.get('http://' + window.location.hostname + `:8081/thumpnailsPaginated?page=${currentPage}`);
      // get data from localhost
      // eslint-disable-next-line
      const myThumps = await axios.get(apiBasePath + `/thumpnailsPaginated?page=${currentPage}`);
      for (let i = 0; i < myThumps.data.thumpnails.length; i++) {
        // eslint-disable-next-line 
        myThumps.data.thumpnails[i].base64string = URL.createObjectURL(await b64toBlob(myThumps.data.thumpnails[i].base64string))
      }
      return myThumps.data
    }

    const transformData = async() => {
      setLoading(true);
      let promises = [
        fetchVehicleOverview(currentPage),
        fetchThumpnails(currentPage)
      ]

      Promise.all(promises).then((values) => {
        var [vehiclesOverview, totalVehicles, thumpnails] = [values[0].vehicles, values[0].totalVehicles, values[1].thumpnails]
        for (let i = 0; i < vehiclesOverview.length; i++) {
          var myID = vehiclesOverview[i].id
          // eslint-disable-next-line
          vehiclesOverview[i].objectURL = thumpnails[thumpnails.findIndex(thumpnail => thumpnail.id === myID)].base64string
        }

        // updating existing DOM elements
        if (device==="mobile" || device==="tablet") {
          if (parseInt(currentPage) === 1) {
            document.getElementById("root").scrollIntoView({behavior: 'auto'})
            document.querySelector("[href='/fahrzeuge']").className = `active`;
            document.querySelector("[href='/bewertungen']").className = `sidebar-link`;
          } else {
            document.getElementsByClassName("page-container")[0].scrollIntoView({behavior: 'auto'})
            document.querySelector("[href='/fahrzeuge']").className = `active`;
            document.querySelector("[href='/bewertungen']").className = `sidebar-link`;
          }
        } else if (device==="desktop") {
          if (parseInt(currentPage) === 1) {
            document.getElementById("root").scrollIntoView({behavior: 'auto'})
            document.querySelector("[href='/fahrzeuge']").className = `active`;
          } else {
            document.getElementsByClassName("page-container")[0].scrollIntoView({behavior: 'auto'})
            document.querySelector("[href='/fahrzeuge']").className = `active`;
          }
        }

        if (isMounted) {
          //passing data to hooks
          setTotalVehicles(totalVehicles)
          setVehiclesOverview(vehiclesOverview); 
          setActive(currentPage)

          //calculate currentVehicles
          calculateCurrentVehicles(parseInt(currentPage), totalVehicles, totalPages).then((values) => {
            setCurrentVehicles(values)
          })
        }
 
        if (location.state !== null) {
          window.scrollTo(0, location.state, "auto")
        }
      })
      setLoading(false);
    }
    transformData();
    if (location.state !== null) {
      window.scrollTo(0, 2000)
    }

  // eslint-disable-next-line
  setLoading(false);
  return () => { isMounted = false }
  // eslint-disable-next-line
  }, [currentPage, totalPages, apiBasePath])

  const handlePrevPage = () => {
    if (currentPage > 1) {
      if (Number(currentPage) === 2) {
        navigate(`/fahrzeuge`)
      } else {
        navigate(`/fahrzeuge?page=${parseInt(currentPage - 1)}`)
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      navigate(`/fahrzeuge&page=${parseInt(currentPage) + 1}`)
    }
  };

  const changePage = e => {
    const pageNumber = Number(e.target.textContent)
    if (pageNumber === 1) {
      navigate(`/fahrzeuge`)
    } else {
      navigate(`/fahrzeuge&page=${parseInt(pageNumber)}`)
    }
  }

  const goToFirstPage = e => {
    navigate(`/fahrzeuge`)
  }

  const goToLastPage = e => {
    navigate(`/fahrzeuge&page=${parseInt(totalPages)}`)
  }

  useEffect(() => {
    const getPaginationGroup = () => {
      let start = Math.floor((myPage - 1) / totalPages) * totalPages
      let pageNumbers = new Array(totalPages).fill().map((_, i) => start + i + 1)
      if (myPage===1 || myPage===2 || myPage===3) {
        pageNumbers = pageNumbers.slice(0,5);
        if (totalPages > 5) {
          pageNumbers.push("...");
        }
      } else if (myPage===totalPages || myPage===totalPages - 1 || myPage===totalPages - 2) {
        pageNumbers = pageNumbers.slice(pageNumbers.length-5,pageNumbers.length);
        if (totalPages > 5) {
          pageNumbers.unshift("...");
        }
      } else {
        pageNumbers = pageNumbers.slice(myPage-3,myPage+2);
        if (totalPages > 5) {
          pageNumbers.unshift("...");
          pageNumbers.push("...");
        }
    }
      return pageNumbers
    }
    setTotalPagesArray(getPaginationGroup())
  }, [myPage, totalPages])


  function Item({ isPageOne }) {
    if (isPageOne) {
      return <div className="vehicle-overview" style={ device === "mobile"  || device === "tablet" ? {textAlign:"left"} : {textAlign:"justify"}}>Nachfolgend finden Sie alle Fahrzeuge die wir derzeit zum Verkauf anbieten. Kommen Sie bei Fragen gerne auf uns zu. Unsere Kontaktdaten finden Sie im Reiter "Kontakt".
      Bitte beachten Sie, dass eine Besichtigung nur nach telefonischer Terminvereinbarung möglich ist.
      </div>;
    }
    return ""
  }

  if(loading) {
    return <div className="Loading">Loading...</div>;
  } else {
    return (
      <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
        <Helmet>
          <title>
            Unsere Fahrzeuge
          </title>
          <meta name="description" content="Hier finden Sie eine Übersicht über alle Fahrzeuge die das Autohaus Kauschinger derzeit zum Verkauf anbietet." />
        </Helmet>
        <div className="page-container-2" style={pointerEvents ? {pointerEvents: "auto"} : {pointerEvents: "none"}}>
          <h1 className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>
            Unsere Fahrzeuge
          </h1>
          <Item isPageOne={parseInt(currentPage)===1} />
          <div className="vehicle-listings">
            Fahrzeuge {currentVehicles[0]} bis {currentVehicles[1]} von insgesamt {totalVehicles} Fahrzeugen
          </div>
            <div>
              <ul className="list-group-vehicles">
                {vehiclesOverview.map(vehicles => {
                  return <li key={vehicles.position} className={ device === "mobile" ? "list-group-vehicle-item-mobile" : "list-group-vehicle-item-desktop"}>
                    <NavLink to={{pathname:`/fahrzeuge/${vehicles.id}`}} state={scrollPosition} className={ device === "mobile" ? "list-group-vehicle-item-mobile" : "list-group-vehicle-item-desktop"} onClick={sendGAevent}>
                      <VehiclelistHeaderline props={vehicles} device={device}/> 
                      <VehiclelistContentline props={vehicles} device={device}/>
                    </NavLink> 
                  </li>; 
                })}
              </ul>
              <ul className="pagination">
                {totalPagesArray.length > 0 && (
                  <div>
                    <button className={ (device === "mobile" || device === "tablet") && isMobile ? "page-reviews-item-mobile" : "page-reviews-item-desk" } onClick={goToFirstPage} disabled={parseInt(currentPage) === 1}>
                      <FaAnglesLeft />
                    </button>
                    <button className={ (device === "mobile" || device === "tablet") && isMobile ? "page-reviews-item-mobile" : "page-reviews-item-desk" } onClick={handlePrevPage} disabled={parseInt(currentPage) === 1}>
                      <FaAngleLeft />
                    </button>
                    {totalPagesArray.map((item, i) => {
                      if (isMobile) {
                        return(
                          <button className={item === parseInt(active) ? "page-reviews-item-mobile-active": "page-reviews-item-mobile"} 
                            onClick={changePage} 
                            key={i} 
                            disabled={item === "..."}>
                              {item}
                          </button>
                        )
                      } else {
                        return(
                          <button className={item === parseInt(active) ? "page-reviews-item-desk-active": "page-reviews-item-desk"} 
                            onClick={changePage} 
                            key={i} 
                            disabled={item === "..."}>
                              {item}
                          </button>
                        )
                      }
                    })}
                    <button className={ (device === "mobile" || device === "tablet") && isMobile ? "page-reviews-item-mobile" : "page-reviews-item-desk" } onClick={handleNextPage} disabled={parseInt(currentPage) === totalPages}>
                      <FaAngleRight />
                    </button>
                    <button className={ (device === "mobile" || device === "tablet") && isMobile ? "page-reviews-item-mobile" : "page-reviews-item-desk" } onClick={goToLastPage} disabled={parseInt(currentPage) === totalPages}>
                    <FaAnglesRight/>
                    </button>
                  </div>
                )}
              </ul>
            </div>
        </div>
      </div>
    );
  }
};

export default Vehicles;