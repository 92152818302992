import React from 'react';
import OverViewStarRatingSingle from './OverViewStarRatingSingle';
import OverViewFeature from './OverViewFeature';

const ReviewOverview = ({summary, loading, twoColumnLayout, device}) => {

    // console.log(device)
    if(loading) {
        return <div className="Loading">Loading...</div>;
    }
    let mySum = summary['0']
    var myReviewsOverAll = String(mySum?.['reviewsOverall']).slice(0,String(mySum?.['reviewsOverall']).search(" "))
    var myMemberSince = String(mySum?.['memberSince']).slice(String(mySum?.['memberSince']).search(" seit ")+6,-1)
    var myReviewsInTwoYears = String(mySum?.['reviewsInTwoYears']).slice(0,String(mySum?.['reviewsInTwoYears']).search(" "))
    var myOverallRating = String(mySum?.['overallRating'])
    var myOverallConsulting = String(mySum?.['overallConsulting'])
    var myOverallFriendliness = String(mySum?.['overallFriendliness'])
    var myOverallResponsiveness = String(mySum?.['overallResponsiveness'])
    var myRecommendation1 = String(mySum?.['recommendation1'])
    var myRecommendation2 = String(mySum?.['recommendation2'])
/*     console.log(myOverallRating) */


    return (
        <>
            <div className={twoColumnLayout ? "reviewline-overview-desk" : "reviewline-overview-mobile"}>
                Im Folgenden finden Sie <strong>{myReviewsOverAll} Bewertungen unseres Autohauses</strong>. Es handelt sich dabei um verifizierte Bewertungen welche über die Plattform <a href="https://www.mobile.de/bewertungen/KAUSCHINGERAUTOMOBILE#1">mobile.de</a> abgegeben wurden.
                Unser Autohaus ist seit dem {myMemberSince} Vertragshändler auf dieser Plattform. Nur Kunden die nachweislich mit uns Kontakt hatten können eine Bewertung abgeben.
            </div>
            <div className={twoColumnLayout ? "reviewline-overview-desk" : "reviewline-overview-mobile"}>
                In den <strong>letzten 24 Monaten wurde unser Autohaus {myReviewsInTwoYears} mal bewertet</strong>. 
                Daraus ergibt sich derzeit folgende Gesamtbewertung:
            </div>
            <div className={twoColumnLayout ? 'overview-ratings-desk' : 'overview-ratings-mobile'}>
                <div className={twoColumnLayout ? 'overview-ratings-left-ragged' : 'overview-ratings-mobile'}>
                    <OverViewStarRatingSingle value={{ value: myOverallRating }} twoColumnLayout={twoColumnLayout} device={device}/>
                    <OverViewStarRatingSingle value={{ value: myOverallResponsiveness }} twoColumnLayout={twoColumnLayout} device={device}/>
                    <OverViewStarRatingSingle value={{ value: myOverallFriendliness }} twoColumnLayout={twoColumnLayout} device={device}/>
                    <OverViewStarRatingSingle value={{ value: myOverallConsulting }} twoColumnLayout={twoColumnLayout} device={device}/>
                </div>
                <div className={twoColumnLayout ? 'overview-ratings-left-ragged' : 'overview-ratings-mobile'} style={ twoColumnLayout ? {} : {paddingTop:'24px'}}>
                    <OverViewFeature value={{ value: myRecommendation1 }} twoColumnLayout={twoColumnLayout} device={device}/>
                    <OverViewFeature value={{ value: myRecommendation2 }} twoColumnLayout={twoColumnLayout} device={device}/>
                </div>
            </div>
        </>
    )

}
  
export default ReviewOverview




