import React, { useState, useEffect } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import ReviewOverview from '../components/ReviewOverview';
import ReviewList from '../components/ReviewList';
import {FaAngleLeft, FaAnglesLeft, FaAngleRight, FaAnglesRight } from 'react-icons/fa6';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';

const Reviews = (props) => {

  const apiBasePath= process.env.REACT_APP_API_BASE_PATH
  // console.log(apiBasePath)

  var { pageID: currentPage }  = useParams();
  if (!currentPage){
    currentPage = 1
  }
  var myPage = parseInt(currentPage)
  const navigate = useNavigate()

  //pagination
  const [currentReviews, setCurrentReviews] = useState([]);
  const [summary, setSummary] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesArray, setTotalPagesArray] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [listinginfo, setListinginfo] = useState(0);
  const [active, setActive] = useState()
  var [device, setDevice] = useState()
  const [twoColumnLayout, setTwoColumnLayout] = useState(props.twoColumnLayout)

  const calculateListingInfo = async (myPage, totalReviews, totalPages) => {
    var endIndex = myPage * 10
    var startIndex = 0

    if (endIndex > totalReviews && totalReviews !== 0) {
      endIndex = totalReviews
      startIndex = (totalPages - 1 ) * 10
      if (startIndex % 10 === 0) {
        startIndex++
      } 
    } else if (totalReviews === 0) {
      endIndex = 0
    } else {
      endIndex = myPage * 10
      // eslint-disable-next-line
      startIndex = endIndex - 9
    }
    return [startIndex, endIndex]
  }

  useEffect(() => {
    setDevice(props.device)
    setTwoColumnLayout(props.twoColumnLayout)
  }, [props])

  useEffect(() => {
    //fetching necessary data
    const fetchReviews = async () => {
      //get data from specific IP
      // const res = await axios.get('http://' + window.location.hostname + `:8081/reviewsPaginated?page=${myPage}`);
      // get data from localhost
      // console.log(apiBasePath + `/reviewsPaginated?page=${myPage}`)
      const res = await axios.get(apiBasePath + `/reviewsPaginated?page=${myPage}`);
      setTotalPages(res.data.totalPages);
      return [res.data.reviews, res.data.totalPages, res.data.totalReviews]
    }
  
    const fetchSummary = async () => {
      //get data from specific IP
      // const sum = await axios.get('http://' + window.location.hostname + ':8081/reviewsOverview');
      // get data from localhost
      // console.log(apiBasePath + '/reviewsOverview')
      const sum = await axios.get(apiBasePath + '/reviewsOverview');
      return sum.data
    }



    if (device==="mobile" || device==="tablet") {
      if (parseInt(currentPage) === 1) {
        document.getElementById("root").scrollIntoView({behavior: 'auto'})
        document.querySelector("[href='/bewertungen']").className = "active";
        // document.querySelector("[href='/fahrzeuge']").className =  "sidebar-link";
      } else {
        document.getElementsByClassName("page-container")[0].scrollIntoView({behavior: 'auto'})
        document.querySelector("[href='/bewertungen']").className = "active";
        // document.querySelector("[href='/fahrzeuge']").className =  "sidebar-link";
      }
    } else if (device==="desktop") {
      if (parseInt(currentPage) === 1) {
        document.getElementById("root").scrollIntoView({behavior: 'smooth'})
        document.querySelector("[href='/bewertungen']").className = `active`;
      } else {
        document.getElementsByClassName("page-container")[0].scrollIntoView({behavior: 'smooth'})
        document.querySelector("[href='/bewertungen']").className = `active`;
      } 
    } 

    //parsing data
    const parseData = async() => {
      setLoading(true);
      if (myPage === 1) {
        fetchSummary()
        .then((myVals) =>{
          setSummary(myVals)
        })
      }
      fetchReviews()        
      .then((myVals) => { 
        //passing data to hooks
        var [currentReviews, totalPages, totalReviews] = [myVals[0], myVals[1], myVals[2]]
        setTotalReviews(totalReviews)
        setCurrentReviews(currentReviews)
        setActive(currentPage)

        //update listing information
        calculateListingInfo(myPage, totalReviews, totalPages)
        .then((values) => {
          setListinginfo(values)
        })
      })
      setLoading(false);
    }
    parseData();
    return () => {
      setSummary({}); // This worked for me
    };
    // eslint-disable-next-line
    }, [myPage, currentPage, apiBasePath]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      if (Number(currentPage) === 2) {
        navigate(`/bewertungen`)
      } else {
        navigate(`/bewertungen&page=${parseInt(currentPage - 1)}`)
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      navigate(`/bewertungen&page=${parseInt(currentPage) + 1}`)
    }
  };

  const changePage = e => {
    const pageNumber = Number(e.target.textContent)
    if (pageNumber===1) {
      navigate(`/bewertungen`)
    } else {
      navigate(`/bewertungen&page=${parseInt(pageNumber)}`)
    } 
  }

  const goToFirstPage = e => {
    navigate(`/bewertungen`)
  }

  const goToLastPage = e => {
    navigate(`/bewertungen&page=${parseInt(totalPages)}`)
  }

  useEffect(() => {
    const getPaginationGroup = () => {
      let start = Math.floor((myPage - 1) / totalPages) * totalPages
      let pageNumbers = new Array(totalPages).fill().map((_, i) => start + i + 1)
      if (myPage===1 || myPage===2 || myPage===3) {
        pageNumbers = pageNumbers.slice(0,5);
        if (totalPages > 5) {
          pageNumbers.push("...");
        }
      } else if (myPage===totalPages || myPage===totalPages - 1 || myPage===totalPages - 2) {
        pageNumbers = pageNumbers.slice(pageNumbers.length-5,pageNumbers.length);
        if (totalPages > 5) {
          pageNumbers.unshift("...");
        }
      } else {
        pageNumbers = pageNumbers.slice(myPage-3,myPage+2);
        if (totalPages > 5) {
          pageNumbers.unshift("...");
          pageNumbers.push("...");
        }
    }
      return pageNumbers
    }
    setTotalPagesArray(getPaginationGroup())
  }, [totalPages, myPage])
  

  return (
    <div className="page-container" style={ device === "mobile" ? {"fontSize":"12pt"} : {} || device === "tablet" ? {"fontSize":"14pt"} : {} }>
      <Helmet>
        <title>
          Unsere Bewertungen
        </title>
        <meta name="description" content="Hier finden Sie alle Bewertungen über unser Autohaus welche über die Plattform mobile.de abgegeben wurden."/>
      </Helmet>
      <div className="page-container-2"> 
        <h1 className="page-heading" style={ device === "mobile" ? {"fontSize":"20px"} : {} || device === "tablet" ? {"fontSize":"22pt"} : {} }>Unsere Bewertungen</h1>
      {myPage === 1 ? <ReviewOverview summary={summary} loading={loading} twoColumnLayout={twoColumnLayout} device={device} /> : 
      <></>
      }
      <ReviewList reviews={currentReviews} loading={loading} startIndex={listinginfo[0]} endIndex={listinginfo[1]} totalReviews={totalReviews} twoColumnLayout={twoColumnLayout}/>
        <ul className="pagination">
          {totalPagesArray.length > 0 && (
            <>
              <button className={ (device === "mobile" || device === "tablet") && isMobile ? "page-reviews-item-mobile" : "page-reviews-item-desk" } onClick={goToFirstPage} disabled={parseInt(currentPage) === 1}>
                <FaAnglesLeft />
              </button>
              <button className={ (device === "mobile" || device === "tablet") && isMobile ? "page-reviews-item-mobile" : "page-reviews-item-desk" } onClick={handlePrevPage} disabled={myPage === 1}>
                <FaAngleLeft />
              </button>
              {totalPagesArray.map((item, i) => {
                if (isMobile) {
                  return(
                    <button className={item === parseInt(active) ? "page-reviews-item-mobile-active": "page-reviews-item-mobile"} 
                      onClick={changePage} 
                      key={i} 
                      disabled={item === "..."}>
                        {item}
                    </button>
                  )
                } else {
                  return(
                    <button className={item === parseInt(active) ? "page-reviews-item-desk-active": "page-reviews-item-desk"} 
                      onClick={changePage} 
                      key={i} 
                      disabled={item === "..."}>
                        {item}
                    </button>
                  )
                }
              })}
              <button className={ (device === "mobile" || device === "tablet") && isMobile ? "page-reviews-item-mobile" : "page-reviews-item-desk" } onClick={handleNextPage} disabled={myPage === totalPages}>
                <FaAngleRight />
              </button>
              <button className={ (device === "mobile" || device === "tablet") && isMobile ? "page-reviews-item-mobile" : "page-reviews-item-desk" } onClick={goToLastPage}  disabled={parseInt(currentPage) === totalPages}>
                <FaAnglesRight/>
              </button>
            </>
          )}
        </ul>
    </div>
    </div>
  );
};
  
export default Reviews;